<template>
  <div class="manage">
    <h5header :name="$t('new.desc3')" :right="false" />
    <div class="manage-con">
      <div v-if="pageClientWidth > 1200" class="manage-top flex">
        <div class="top-left">
          <div class="top-title">{{ $t("manage.desc49") }}</div>
          <div class="top-name">{{ $t("manage.desc1") }}</div>
          <div class="top-list flex">
            <div class="info">
              <div>{{ $t("manage.desc16", { n1: info.planetCount }) }}</div>
              <p>{{ $t("manage.desc2") }}</p>
            </div>
            <div class="info">
              <div>{{ info.sellLandCount }}/{{ info.landCount }}</div>
              <p>{{ $t("manage.desc3") }}</p>
            </div>
            <div class="info">
              <div>{{ info.totalTaxAmount }} USDT</div>
              <p>{{ $t("manage.desc4") }}</p>
            </div>
            <div class="info">
              <div>{{ info.totalTaxAmountDaot }} DAOT</div>
              <p>{{ $t("manage.desc4") }}</p>
            </div>
            <div class="info">
              <div>{{ info.todayTaxAmount }} USDT</div>
              <p>{{ $t("manage.desc48") }}</p>
            </div>
            <div class="info">
              <div>{{ info.todayTaxAmountDaot }} DAOT</div>
              <p>{{ $t("manage.desc48") }}</p>
            </div>
            <div class="info">
              <div>{{ info.todayInvestAmount }} USDT</div>
              <p>{{ $t("manage.desc5") }}</p>
            </div>
            <div class="info">
              <div>{{ info.todayInvestAmountDaot }} DAOT</div>
              <p>{{ $t("manage.desc5") }}</p>
            </div>
            <div class="info">
              <div>{{ info.totalInvestAmount }} USDT</div>
              <p>{{ $t("manage.desc6") }}</p>
            </div>
            <div class="info">
              <div>{{ info.totalInvestAmount }} DAOT</div>
              <p>{{ $t("manage.desc6") }}</p>
            </div>
            <div class="info">
              <div>{{ info.recommendUserCount }}</div>
              <p>
                {{ $t("manage.desc50")
                }}<el-tooltip class="box-item" raw-content effect="dark" :content="$t('manage.desc53')"
                  placement="bottom">
                  <img src="../../public/images/new/tips.png" alt="" />
                </el-tooltip>
              </p>
            </div>
            <div class="info">
              <div>{{ info.recommendPlanetCount }}</div>
              <p class="flex">
                {{ $t("manage.desc51")
                }}<el-tooltip class="box-item" raw-content effect="dark" :content="$t('manage.desc54')"
                  placement="bottom">
                  <img src="../../public/images/new/tips.png" alt="" />
                </el-tooltip>
              </p>
            </div>
            <div class="info">
              <div class="color1">{{ $t('manage.desc82', { n1: info.transCount }) }}</div>
              <p>
                {{ $t("manage.desc80")
                }}
              </p>
            </div>
            <div class="info">
              <div class="color1">{{ $t('manage.desc82', { n1: info.revicedCount }) }}</div>
              <p>
                {{ $t("manage.desc81")
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="top-right">
          <!-- <img src="../../public/images/new/planetbg.png" alt="" class="img1" /> -->
          <div class="img1">
            <star-img :idNameBF="idNameBF" :sphereImg="fqImg" :starWidth="170" :starHeight="170" />
          </div>
          <div class="line-info">
            <div>{{ $t("manage.desc7") }}</div>
            <p>{{ $t("manage.desc8", { n1: info.finishLandCount }) }}</p>
            <p>{{ $t("manage.desc9", { n1: info.finishLandAmount }) }}</p>
          </div>
          <div class="line-info1">
            <p>{{ $t("manage.desc8", { n1: info.developedLandCount }) }}</p>
            <p>{{ $t("manage.desc9", { n1: info.developedLandAmount }) }}</p>
            <div>{{ $t("manage.desc11") }}</div>
          </div>
          <div class="line-info2">
            <div>{{ $t("manage.desc10") }}</div>
            <p>{{ $t("manage.desc8", { n1: info.discardedLandCount }) }}</p>
            <p>{{ $t("manage.desc9", { n1: info.discardedLandAmount }) }}</p>
          </div>
          <div class="line-info3">
            <p>{{ $t("manage.desc8", { n1: info.toBeDevelopedLandCount }) }}</p>
            <p>
              {{ $t("manage.desc9", { n1: info.toBeDevelopedLandAmount }) }}
            </p>
            <div>{{ $t("manage.desc47") }}</div>
          </div>
        </div>
      </div>
      <div v-else class="h5manage-top">
        <div class="top-title">{{ $t("manage.desc49") }}</div>
        <div class="top-name">{{ $t("manage.desc1") }}</div>
        <div class="top-list flex">
          <div class="info">
            <div>{{ $t("manage.desc16", { n1: info.planetCount }) }}</div>
            <p>{{ $t("manage.desc2") }}</p>
          </div>
          <div class="info">
            <div>{{ info.sellLandCount }}/{{ info.landCount }}</div>
            <p>{{ $t("manage.desc3") }}</p>
          </div>
          <div class="info">
            <div>{{ info.totalTaxAmount }} USDT</div>
            <p>{{ $t("manage.desc4") }}</p>
          </div>
          <div class="info">
            <div>{{ info.totalTaxAmountDaot }} DAOT</div>
            <p>{{ $t("manage.desc4") }}</p>
          </div>
          <div class="info">
            <div>{{ info.todayTaxAmount }} USDT</div>
            <p>{{ $t("manage.desc48") }}</p>
          </div>
          <div class="info">
            <div>{{ info.todayTaxAmountDaot }} DAOT</div>
            <p>{{ $t("manage.desc48") }}</p>
          </div>
          <div class="info">
            <div>{{ info.todayInvestAmount }} USDT</div>
            <p>{{ $t("manage.desc5") }}</p>
          </div>
          <div class="info">
            <div>{{ info.todayInvestAmountDaot }} DAOT</div>
            <p>{{ $t("manage.desc5") }}</p>
          </div>
          <div class="info">
            <div>{{ info.totalInvestAmount }} USDT</div>
            <p>{{ $t("manage.desc6") }}</p>
          </div>
          <div class="info">
            <div>{{ info.totalInvestAmount }} DAOT</div>
            <p>{{ $t("manage.desc6") }}</p>
          </div>
          <div class="info">
            <div>{{ info.recommendUserCount }}</div>
            <p>
              {{ $t("manage.desc50")
              }}<el-tooltip class="box-item" raw-content effect="dark" :content="$t('manage.desc53')" placement="bottom">
                <img src="../../public/images/new/tips.png" alt="" />
              </el-tooltip>
            </p>
          </div>
          <div class="info">
            <div>{{ info.recommendPlanetCount }}</div>
            <p>
              <span>{{ $t("manage.desc51")
              }}</span>
              <el-tooltip class="box-item" raw-content effect="dark" :content="$t('manage.desc54')" placement="bottom">
                <img src="../../public/images/new/tips.png" alt="" />
              </el-tooltip>
            </p>
          </div>
          <div class="info">
            <div class="color1">{{ $t('manage.desc82', { n1: info.transCount }) }}</div>
            <p>
              {{ $t("manage.desc80")
              }}
            </p>
          </div>
          <div class="info">
            <div class="color1">{{ $t('manage.desc82', { n1: info.revicedCount }) }}</div>
            <p>
              {{ $t("manage.desc81")
              }}
            </p>
          </div>
        </div>
        <div class="top-list">
          <div class="img1">
            <star-img :idNameBF="idNameBF" :sphereImg="fqImg" :starWidth="150" :starHeight="150" />
          </div>
          <div class="top-total">
            <div class="line-info">
              <div>{{ $t("manage.desc7") }}</div>
              <p>{{ $t("manage.desc8", { n1: info.finishLandCount }) }}</p>
              <p>{{ $t("manage.desc9", { n1: info.finishLandAmount }) }}</p>
            </div>
            <div class="line-info">
              <div>{{ $t("manage.desc11") }}</div>
              <p>{{ $t("manage.desc8", { n1: info.developedLandCount }) }}</p>
              <p>{{ $t("manage.desc9", { n1: info.developedLandAmount }) }}</p>
            </div>
            <div class="line-info">
              <div>{{ $t("manage.desc10") }}</div>
              <p>{{ $t("manage.desc8", { n1: info.discardedLandCount }) }}</p>
              <p>{{ $t("manage.desc9", { n1: info.discardedLandAmount }) }}</p>
            </div>
            <div class="line-info">
              <div>{{ $t("manage.desc47") }}</div>
              <p>
                {{ $t("manage.desc8", { n1: info.toBeDevelopedLandCount }) }}
              </p>
              <p>
                {{ $t("manage.desc9", { n1: info.toBeDevelopedLandAmount }) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="activity-wrap flex" v-if="planetInfo.act == 1">
          
          <div class="wrap-item flex" v-if="planetInfo.act == 1">
            <img src="../../public/images/reward/planetimg.png" alt="" class="img1" />
            <div class="item-info">
              <div class="info-name flex">
                {{ $t('reward.desc171') }} ！
              </div>
              <div class="info-desc">
                {{ $t('reward.desc209') }}
              </div>
            </div>
            <img src="../../public/images/reward/icon18.png" alt="" class="img2"
              @click="$router.push('/reward/planet')" />
          </div>
        </div>
        <div class="manage-name flex">
        <p>{{ $t("manage.desc12") }}</p>
        <div class="manage-btn flexcenter" @click="$router.push('/manage/record')">{{ $t('manage.desc87') }}</div>
      </div>
      <div class="type-select animate__animated animate__fadeInUp animate__delay-.8s">
        <div class="select">
          <div>{{ $t("manage.desc84") }}</div>
          <p>
            <el-select v-model="transType" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="$t('manage.desc85')" :value="1"></el-option>
              <el-option :label="$t('manage.desc86')" :value="2"></el-option>
              <el-option :label="$t('reward.desc223')" :value="3"></el-option>
            </el-select>
          </p>
        </div>
        <div class="select">
          <div>{{ $t("node.desc4") }}</div>
          <p>
            <el-select v-model="priceOrder" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="$t('node.desc36')" :value="1"></el-option>
              <el-option :label="$t('node.desc37')" :value="2"></el-option>
            </el-select>
          </p>
        </div>
        <div class="select">
          <div>{{ $t("record.desc4") }}</div>
          <p>
            <el-select v-model="typeId" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="item.name" :value="item.id" v-for="item in typeList" :key="item.id"></el-option>
            </el-select>
          </p>
        </div>
        <div class="select">
          <div>{{ $t("market.desc3") }}</div>
          <p>
            <el-select v-model="landStatus" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="$t('market.desc7')" :value="1"></el-option>

              <el-option :label="$t('market.desc8')" :value="2"></el-option>
              <el-option :label="$t('market.desc9')" :value="0"></el-option>
            </el-select>
          </p>
        </div>
        <div class="select">
          <div>{{ $t("node.desc8") }}</div>
          <p class="flex">
            <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" v-model="track" :placeholder="$t('home.desc55')"
              @change="hanlderSelect" />
            <input type="tel" v-model="no" oninput="value=value.replace(/[^\d]/g,'')" :placeholder="$t('home.desc56')"
              @change="hanlderSelect" />
          </p>
        </div>
      </div>

    
      <div v-if="pageClientWidth > 1200" class="manage-list">
        <div class="list-info flex" v-for="item in list" :key="item.id">
          <div class="list-tag" v-if="item.isGive">
            <p>{{ $t('manage.desc57') }}</p>
          </div>
          <div class="list-tag" v-if="item.buyType==1">
            <p>{{ $t('reward.desc224') }}</p>
          </div>
          <div class="logo">
            <star-img :sphereImg="item.icon" :starWidth="170" :starHeight="170" />
          </div>
          <div class="info">
            <div class="info-name">{{ $t("manage.desc13") }}</div>
            <div class="info-title">
              {{ item.planetName }}
              <span>L{{ item.levelId }} {{ item.levelName }}</span>
              <span v-if="item.recommendUserAccount != '' && item.levelId != 1">{{
                $t("manage.desc52", { n1: item.recommendUserAccount })
              }}</span>
            </div>
            <div class="info-total flex" :class="{ eninfo: lang == 'en_US' }">
              <div class="total">
                <div>{{ item.track }}-{{ item.no }}</div>
                <p>{{ $t("node.desc19") }}</p>
              </div>
              <div class="total">
                <div>{{ item.sellLandCount }}/{{ item.landCount }}</div>
                <p>{{ $t("manage.desc46") }}</p>
              </div>
              <div class="total">
                <div>
                  {{ $t("manage.desc16", { n1: item.discardedCount }) }}
                </div>
                <p>{{ $t("manage.desc10") }}</p>
              </div>
              <div class="total">
                <div>
                  {{ $t("manage.desc16", { n1: item.toBeDevelopedLand }) }}
                </div>
                <p>{{ $t("manage.desc45") }}</p>
              </div>
              <div class="total">
                <div>{{ item.investAmount }} DAOT</div>
                <p>{{ $t("manage.desc6") }}</p>
              </div>
              <div class="total">
                <div>{{ item.taxAmount }} DAOT</div>
                <p>{{ $t("manage.desc18") }}</p>
              </div>
            </div>
          </div>
          <div class="list-btn">
            <div class="btn-desc" v-if="item.description == ''">
              {{ $t("manage.desc14") }}
            </div>
            <div class="btn-desc" v-else>{{ item.description }}</div>
            <div class="btn-all flex">
              <div class="flexcenter" @click="handlerTransfer(item)" v-if="isOpen == 1">
                {{ $t("manage.desc56") }}
              </div>
              <div class="flexcenter" @click="handlerInfo(item)">
                {{ $t("manage.desc15") }}
              </div>
              <div class="flexcenter" @click="handlerInfo1(item)">
                {{ $t("planet.desc13") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="h5manage-list">
        <div class="h5list-info" v-for="item in list" :key="item.id">
          <div class="list-tag" v-if="item.isGive">
            <p>{{ $t('manage.desc57') }}</p>
          </div>
          <div class="list-tag" v-if="item.buyType==1">
            <p>{{ $t('reward.desc224') }}</p>
          </div>
          <div class="info-top flex">
            <div class="logo">
              <star-img :sphereImg="item.icon" :starWidth="100" :starHeight="100" />
            </div>
            <div class="top-con">
              <div class="top-icon flex">{{ $t("manage.desc13") }}</div>
              <div class="info-title flex">
                <div class="ellipse">{{ dealAddress(item.planetName) }}</div>
                <p>L{{ item.levelId }} {{ item.levelName }}</p>
                <p v-if="item.recommendUserAccount != '' && item.levelId != 1">
                  {{ $t("manage.desc52", { n1: item.recommendUserAccount }) }}
                </p>
              </div>
              <div class="info-desc">
                <p v-if="item.description == ''">{{ $t("manage.desc14") }}</p>
                <p v-else>{{ item.description }}</p>
              </div>
            </div>
            <div class="top-btnall">
              <div class="flexcenter" @click="handlerInfo(item)">
                {{ $t("manage.desc15") }}
              </div>
              <div class="flexcenter" @click="handlerInfo1(item)">
                {{ $t("planet.desc13") }}
              </div>
            </div>
          </div>
          <div class="info-total flex">
            <div class="total">
              <div>{{ item.track }}-{{ item.no }}</div>
              <p>{{ $t("node.desc19") }}</p>
            </div>
            <div class="total">
              <div>{{ item.sellLandCount }}/{{ item.landCount }}</div>
              <p>{{ $t("manage.desc46") }}</p>
            </div>
            <div class="total">
              <div>{{ $t("manage.desc16", { n1: item.discardedCount }) }}</div>
              <p>{{ $t("manage.desc10") }}</p>
            </div>
            <div class="total">
              <div>
                {{ $t("manage.desc16", { n1: item.toBeDevelopedLand }) }}
              </div>
              <p>{{ $t("manage.desc45") }}</p>
            </div>
            <div class="total">
              <div>{{ item.investAmount }} DAOT</div>
              <p>{{ $t("manage.desc6") }}</p>
            </div>
            <div class="total">
              <div>{{ item.taxAmount }} DAOT</div>
              <p>{{ $t("manage.desc18") }}</p>
            </div>
          </div>
          <div class="top-btnall1">
            <div class="flexcenter" @click="handlerTransfer(item)" v-if="isOpen == 1">
              {{ $t("manage.desc56") }}
            </div>
            <div class="flexcenter" @click="handlerInfo(item)">
              {{ $t("manage.desc15") }}
            </div>
            <div class="flexcenter" @click="handlerInfo1(item)">
              {{ $t("planet.desc13") }}
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
      <div class="planet-page">
        <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page" :total="totals"
          :hide-on-single-page="true" @current-change="handlerCurrent">
        </el-pagination>
      </div>

      <PageLoading v-model:show="showLoading" />
    </div>
    <!-- 提示 -->
    <nodeWrappar v-if="showInfo" :nodeId="nodeId" :isGive="isGive" :level="level" @handlerClose="handlerClose"
      @handlerBuy="handlerBuy" />
    <marketInfo :planetId="nodeId" :levelId="level" @handlerClose="handlerClose1" :type="1" v-if="showInfo1" />
    <!-- 查收提示 -->
    <el-dialog v-model="showTips" :append-to-body="true">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="handlerRead" />
        <div class="modal-title">{{ $t("common.desc4") }}</div>
        <div class="modal-desc" v-html="$t('manage.desc79', {
          n1: tipsInfo.friendAccount, n2: tipsInfo.createTime, n3:
            `${tipsInfo.track}-${tipsInfo.no}`, n4: tipsInfo.planetType
        })"></div>
        <div class="modal-btncon flexcenter" @click="handlerRead">{{ $t('market.desc130') }}</div>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import h5header from "@/componets/h5header.vue";
import nodeWrappar from "../../componets/nodeInfo.vue";
import starImg from "@/componets/starImg.vue";
import marketInfo from "@/componets/marketInfo.vue";
import bus from "@/utils/bus";
export default {
  components: {
    h5header,
    nodeWrappar,
    starImg,
    marketInfo,
  },
  data() {
    return {
      track: '',
      no: '',
      transType: '',
      priceOrder: '',
      typeId: '',
      landStatus: '',
      showTips: false,
      showInfo1: false,
      showInfo: false,
      page: 1,
      pageSize: 10,
      totals: 0,
      showEdit: false,
      info: {},
      list: [],
      showNull: false,
      nodeId: "",
      level: "",
      lang: "",
      fqImg: require("../../public/images/new/planetbg.png"),
      idNameBF: "",
      showLoading: true,
      tipsInfo: {},
      isGive: false,
      typeList: [],
      isOpen: 1,
      configInfo2:{},
      planetInfo:{}
    };
  },
  computed: {
    ...mapState(["pageClientWidth"]),
  },
  mounted() {
    this.$post(this.URL.reward.marketConfig, {}).then(res => {
      if (res.code == 0) {
        let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();

        if (todayTime > res.data.endTimeStamp + time1) {
          res.data.act = 2;
        } else if (todayTime < res.data.startTimeStamp + time1) {
          res.data.act = 0;

        } else {

          res.data.act = 1;
        }
        res.data.coinName = res.data.list[0].coinName;
        if (res.data.accountType == 1) {
          res.data.awardAccountTypeName = this.$t('new.desc7')
        } else if (res.data.accountType == 2) {
          res.data.awardAccountTypeName = this.$t('new.desc8')
        } else {
          res.data.awardAccountTypeName = this.$t('new.desc9')
        }
        this.configInfo2 = res.data;
      }
    })
    this.$post(this.URL.reward.planetConfig, {
      type: 1
    }).then(res => {
      if (res.code == 0) {
        let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime(), startTime = new Date(res.data.startTime).getTime() + time1, endTime = new Date(res.data.endTime).getTime() + time1;
        if (todayTime > endTime) {
          res.data.act = 2;
        } else if (todayTime < startTime) {
          res.data.act = 0;
          res.data.time = startTime - todayTime;
        } else {
          res.data.time = endTime - todayTime
          res.data.act = 1;
        }
        this.planetInfo = res.data;
      }
    })
    this.$post(this.URL.manage.hasopen, {}).then(res => {
      if (res.code == 0) {
        this.isOpen = res.data.isOpen
      }
    })
    this.handleInit();
    this.$post(this.URL.manage.message, {
      page: 1,
      pageSize: 1
    }).then(res => {
      if (res.code == 0) {
        let info = res.data.records[0];
        if (info != undefined) {
          // let time = this.common.dateBJtoLocal(info.createTime), time1 = time.getTime(), time2 = 4 * 60 * 60 * 1000;
          // info.datetime = this.common.formatDate(time1 + time2);
          this.tipsInfo = info;
          this.showTips = true;
        }
      }
    })
    this.$post(this.URL.star.type, {}).then(res => {
      if (res.code == 0) {
        this.typeList = res.data;
      }
    })
  },
  methods: {
    hanlderSelect(val) {
      this.page = 1;
      this.list = [];
      this.getlist();
    },
    handlerRead() {
      this.$post(this.URL.manage.read, {
        id: this.tipsInfo.id
      }).then(res => {
        bus.emit('handlerTips')
        this.showTips = false;
      })
    },
    handlerTransfer(info) {
      localStorage.setItem('planetInfo', JSON.stringify(info))
      this.$router.push(`/manage/transfer?planetId=${info.planetId}`)
    },
    async handleInit() {
      this.lang = localStorage.getItem("language");
      try {
        const res = await this.$post(this.URL.manage.info, {});
        if (res.code == 0) {
          this.info = res.data;
        }
        await this.getlist();
        this.showLoading = false;
      } catch (error) {
        this.showLoading = false;
      }
    },
    handlerBuy() {
      this.showInfo = false;
      setTimeout(() => {
        this.showInfo1 = true;
      }, 500);
    },
    handlerClose1() {
      this.showInfo1 = false;
    },
    handlerClose() {
      localStorage.removeItem('giveUser')
      this.showInfo = false;
    },
    handlerInfo1(info) {
      this.nodeId = info.planetId;
      this.level = info.levelId;
      localStorage.setItem('giveUser', info.giveUser)
      info.isGive == '赠' || info.buyType==1? this.isGive = true : this.isGive = false;
      this.showInfo = true;
    },
    dealAddress(str) {
      if (str && str.length > 10) {
        return str.substring(0, 10) + "....";
      } else {
        return str;
      }
    },
    handlerInfo(info) {
      this.$post(this.URL.node.modify, {
        planetId: info.planetId,
      }).then((res) => {
        if (res.code == 0) {
          let data = {
            icon: res.data.icon,
            days: res.data.editValidDays,
            name: res.data.name,
            description: res.data.description,
          };
          this.$store.commit("SET_MODIFY_DATA", data);
          this.$router.push(`/manage/modify?id=${info.planetId}&type=1`);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    async getlist() {
      let timeStr = new Date().getTime();
      this.idNameBF = "star-img-" + timeStr + Math.random();
      this.showNull = false;
      const res = await this.$post(this.URL.manage.list, {
        page: this.page,
        pageSize: this.pageSize,
        priceOrder: this.priceOrder,
        track: this.track,
        no: this.no,
        typeId: this.typeId,
        landStatus: this.landStatus,
        transType: this.transType
      });
      if (res.code == 0) {
        this.list = res.data.list;
        this.list.forEach((e) => {
          if (e.recommendUserAccount != "recommendUserAccount") {
            e.recommendUserAccount = this.dealAddress(e.recommendUserAccount);
          }
        });
        this.totals = parseInt(res.data.totalCount);
        if (this.totals == 0) {
          this.showNull = true;
        }
      } else {
        this.$message.error(res.message);
      }
    },
    dealAddress(str) {
      if (str && str.length > 3) {
        return (
          str.substring(0, 1) +
          "****" +
          str.substring(str.length - 1, str.length)
        );
      } else {
        return str;
      }
    },
    handlerCurrent(val) {
      this.page = val;
      this.list = [];
      this.getlist();
    },
  },
};
</script>
  
<style lang="less" scoped>
.manage {
  width: 100%;
  min-height: 100vh;
  padding: 136px 0 80px;
  background: url("../../public/images/new/landbg.png") center no-repeat;
  background-size: 100% 100%;

  .manage-con {
    width: 1240px;
    margin: 0 auto;

    .manage-top {
      margin-bottom: 40px;
      justify-content: space-between;

      .top-left {
        flex: 1;

        .top-name {
          width: 640px;
          margin: 12px 0 32px;
          font-size: 20px;
          color: #fff;
        }

        .top-title {
          font-size: 24px;
          color: #fff;
          font-weight: bold;
        }

        .top-list {
          width: 780px;
          flex-wrap: wrap;

          .info {
            flex: 0 0 20%;
            font-size: 13px;
            color: #a8a8a8;
            line-height: 12px;

            &:nth-last-child(-n + 9) {
              margin-top: 22px;
            }

            .color1 {
              color: #C9FA5B
            }

            &:last-child {
              flex: 1;
              margin-right: 0;
            }

            div {
              margin-bottom: 8px;
              font-size: 16px;
              line-height: 18px;
              color: #ffffff;
            }

            p {
             
              img {
                width: 12px;
                height: 12px;
                margin-left: 4px;
              }
            }
          }
        }
      }

      .top-right {
        flex: 0 0 368px;
        padding-top: 18px;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .img1 {
          width: 140px;
          height: 140px;
          margin-left: 30px;
        }

        .line-info {
          position: relative;
          position: absolute;
          top: 39px;
          left: -22px;
          width: 150px;
          text-align: right;

          &::after {
            content: "";
            position: absolute;
            top: 8px;
            right: -58px;
            width: 58px;
            height: 12px;
            background: url("../../public/images/new/line1.png") center no-repeat;
            background-size: 100% 100%;
          }

          div {
            margin-bottom: 10px;
            font-size: 16px;
            color: #46bb57;
            margin-right: 5px;
          }

          p {
            font-size: 12px;
            color: #a8a8a8;
            line-height: 12px;
            margin-right: 5px;

            &:last-child {
              margin-top: 6px;
            }
          }
        }

        .line-info1 {
          position: relative;
          position: absolute;
          bottom: 35px;
          left: 0px;
          width: 150px;
          text-align: right;

          &::after {
            content: "";
            position: absolute;
            bottom: 8px;
            right: -16px;
            width: 16px;
            height: 16px;
            background: url("../../public/images/new/line3.png") center no-repeat;
            background-size: 100% 100%;
          }

          div {
            margin-top: 10px;
            margin-right: 5px;
            font-size: 16px;
            color: #c9fa5b;
          }

          p {
            margin-right: 5px;
            font-size: 12px;
            color: #a8a8a8;
            line-height: 12px;
            margin-top: 6px;
          }
        }

        .line-info2 {
          position: relative;
          position: absolute;
          top: 59px;
          right: -20px;
          width: 150px;
          text-align: left;

          &::after {
            content: "";
            position: absolute;
            top: 9px;
            left: -3px;
            width: 33px;
            height: 8px;
            background: url("../../public/images/new/line2.png") center no-repeat;
            background-size: 100% 100%;
          }

          div {
            margin-bottom: 10px;
            font-size: 16px;
            color: #ffffff;
            margin-left: 30px;
          }

          p {
            font-size: 12px;
            color: #a8a8a8;
            line-height: 12px;
            margin-left: 30px;

            &:last-child {
              margin-top: 6px;
            }
          }
        }

        .line-info3 {
          position: relative;
          position: absolute;
          bottom: 23px;
          right: -27px;
          width: 150px;
          text-align: left;

          &::after {
            content: "";
            position: absolute;
            top: 20px;
            left: -3px;
            width: 17px;
            height: 17px;
            background: url("../../public/images/new/line4.png") center no-repeat;
            background-size: 100% 100%;
          }

          div {
            margin-top: 10px;
            margin-left: 20px;
            font-size: 16px;
            color: #f8a430;
          }

          p {
            margin-left: 20px;
            font-size: 12px;
            color: #a8a8a8;
            line-height: 12px;
            margin-top: 6px;
          }
        }
      }
    }
    .activity-wrap {
      margin: 40px 0;

      .wrap-item {
        flex: 1;
        padding: 20px;
        border: 1px solid rgba(255, 255, 255, 0.20);

        &:nth-child(2) {
          margin-left: 20px;
        }

        .img1 {
          flex: 0 0 72px;
          width: 72px;
          height: 72px;
          margin-right: 24px;
        }

        .item-info {
          flex: 1;
          font-size: 20px;
          line-height: 24px;

          .info-name {

            color: #C9FA5B;

            img {
              width: 24px;
              height: 24px;
              margin-left: 12px;
            }
          }

          .info-desc {

            margin-top: 16px;
            color: rgba(255, 255, 255, 0.8);
          }
        }

        .img2 {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
          margin: 24px 0 0 24px;
          cursor: pointer;
        }
      }
    }
    .manage-name {
      margin-bottom: 20px;
      font-size: 20px;
      color: #ffffff;
      justify-content: space-between;

      p {
        line-height: 40px;
      }

      .manage-btn {
        flex: 0 0 120px;
        width: 120px;
        height: 40px;
        font-size: 14px;
        background: url("../../public/images/new/btnbg33.png") center no-repeat;
        background-size: 100% 100%;
        color: #000;
        cursor: pointer;
        text-align: center;
      }

    }

    .list-info {
      position: relative;
      padding: 24px 0 24px 32px;
      border-radius: 16px;
      background: #181818;
      margin-bottom: 16px;

      .list-tag {
        position: absolute;
        top: -20px;
        right: -20px;
        width: 80px;
        height: 80px;
        background: url('../../public/images/new/tagbg.png') center no-repeat;
        background-size: 100% 100%;

        p {
          margin-top: 25px;
          height: 20px;
          font-size: 16px;
          color: #374C00;
          font-weight: 600;
          text-align: center;
          line-height: 20px;
          transform: rotate(45deg);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .logo {
        flex: 0 0 140px;
        width: 140px;
        height: 140px;
        margin-right: 32px;
        border-radius: 50%;
        object-fit: cover;
      }

      .info {
        flex: 1;
        overflow: hidden;

        .info-name {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          line-height: 14px;
        }

        .info-title {
          margin: 16px 0 28px;
          font-size: 20px;
          color: #ffffff;
          line-height: 20px;

          span {
            margin-left: 8px;
            padding: 2px 8px;
            background: rgba(201, 250, 91, 0.1);
            font-size: 12px;
            color: #c9fa5b;
            border-radius: 4px;
            line-height: 12px;

            &:last-child {
              background: rgba(248, 164, 48, 0.1);
              color: #f8a430;
            }
          }
        }

        .info-total {
          flex-wrap: wrap;

          .total {
            margin-right: 40px;
            font-size: 16px;
            color: #ffffff;
            line-height: 18px;

            div {
              color: rgba(255, 255, 255, 0.6);
            }

            p {
              margin-top: 8px;
              font-size: 13px;
              color: #d4cccc;
              line-height: 13px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .eninfo {
          .total {
            flex: 0 0 25%;
            margin-right: 0;

            &:nth-child(-n + 4) {
              margin-bottom: 16px;
            }
          }
        }
      }

      .list-btn {
        flex: 0 0 400px;
        border-left: 1px solid rgba(217, 217, 217, 0.1);

        .btn-desc {
          padding: 0 40px;
          font-size: 14px;
          color: #ffffff;
        }

        .btn-all {
          margin: 25px 0 0 40px;

          div {
            width: 102px;
            height: 36px;
            margin-left: 12px;
            background: url("../../public/images/new/btnbg32.png") center no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            text-align: center;
            line-height: 38px;
            font-size: 14px;
            color: #fff;

            &.disable {
              background-image: url("../../public/images/new/btnbg15_disable.png");
            }

            &:first-child {
              margin-left: 0;
              background: url("../../public/images/new/btnbg33.png") center no-repeat;
              background-size: 100% 100%;
              color: #000;
            }
          }
        }
      }
    }
  }
}



.type-select {
  display: flex;
  margin-bottom: 24px;

  .select {
    flex: 0 0 160px;
    margin-right: 24px;

    &:last-child {
      flex: 0 0 240px;
      margin-right: 0;

      p {
        width: 100%;
        border: 0;
      }

      input {
        width: 49%;
        padding: 0 14px;
        height: 40px;
        font-size: 16px;
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;

        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
        }

        &:last-child {
          margin-left: 2%;
        }
      }
    }

    div {
      margin-bottom: 10px;
      font-size: 13px;
      color: #a8a8a8;
    }

    p {
      width: 100%;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      ::v-deep .el-select {
        width: 100%;
        height: 38px;
        color: #fff;

        .el-input {
          height: 38px;

          .el-input__wrapper {
            background-color: rgba(255, 255, 255, 0);
            box-shadow: none !important;
          }

          .el-input__inner {
            color: #fff;
          }
        }
      }
    }
  }
}

.no-data {
  width: 100%;
  padding: 130px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}

.planet-page {
  margin-top: 40px;
  padding: 0;

  ::v-deep .el-pagination {
    button {
      background-color: rgba(255, 255, 255, 0);
      color: #666666;
      font-size: 14px;
    }

    .el-pager {
      li {
        background: rgba(255, 255, 255, 0);
        font-size: 14px;
        color: #666666;
      }

      .is-active {
        background: #c9fa5b !important;
        border-radius: 4px !important;
        color: #000000 !important;
      }
    }
  }
}

.h5manage-list,
.h5manage-top {
  display: none;
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }


  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }


}

@media (max-width: 1200px) {
  .manage {
    padding: 88px 0;

    .manage-con {
      width: calc(100vw - 8px);

      .manage-top {
        display: none;
        flex-wrap: wrap;

        .top-left {
          flex: 1;

          .top-name {
            width: 100%;
            margin-bottom: 20px;
          }

          .top-list {
            flex-wrap: wrap;

            .info {
              margin-top: 16px;
              flex: 0 0 33.3%;
              margin-right: 0;

              &:nth-child(-n + 3) {
                margin-top: 0;
              }

              &:last-child {
                flex: 0 0 33.3%;
              }
            }
          }
        }

        .top-right {
          flex: 0 0 368px;
          margin-top: 20px;
          height: fit-content;
        }
      }
      .activity-wrap {
        margin: 12px 0;
        width: 100%;
        justify-content: left;

        .wrap-item {
          flex: 1;
          padding: 12px;

          &:nth-child(2) {
            margin-left: 24px;
          }

          .img1 {
            flex: 0 0 48px;
            width: 48px;
            height: 48px;
            margin-right: 12px;
          }

          .item-info {
            font-size: 14px;
            line-height: normal;

            .info-name {
              img {
                width: 14px;
                height: 14px;
              }
            }

            .info-desc {
              margin-top: 4px;
            }
          }

          .img2 {
            margin: 12px 0 0 12px;
          }
        }
      }
      .list-info {
        padding: 16px;
        flex-wrap: wrap;
      }

      .manage-list {
        display: none;
      }
    }
  }

  .type-select {
    flex-wrap: wrap;

    .select {
      flex: 0 0 23%;
      margin-right: 2%;

      &:last-child {
        flex: 0 0 25%;
      }
    }
  }

  .h5manage-list {
    display: block;

    .h5list-info {
      position: relative;
      padding: 16px;
      margin-bottom: 10px;
      border-radius: 8px;
      background: #181818;

      .list-tag {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 48px;
        height: 48px;
        background: url('../../public/images/new/tagbg.png') center no-repeat;
        background-size: 100% 100%;

        p {
          margin-top: 17px;
          height: 14px;
          font-size: 10px;
          color: #374C00;
          font-weight: 600;
          text-align: center;
          line-height: 14px;
          transform: rotate(45deg);
        }
      }

      .info-top {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(217, 217, 217, 0.1);

        .logo {
          flex: 0 0 80px;
          width: 80px;
          height: 80px;
          margin-right: 12px;
          border-radius: 50%;
          object-fit: cover;
        }

        .top-con {
          flex: 1;

          .top-icon {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);

            img {
              width: 12px;
              height: 12px;
              margin-left: 8px;
            }
          }

          .info-title {
            margin: 10px 0 12px;
            font-size: 16px;
            color: #ffffff;

            div {
              flex: initial;
              overflow: hidden;
            }

            p {
              margin-left: 8px;
              padding: 2px 4px;
              background: rgba(201, 250, 91, 0.1);
              font-size: 12px;
              color: #c9fa5b;
              border-radius: 4px;

              &:last-child {
                background: rgba(248, 164, 48, 0.1);
                color: #f8a430;
              }
            }
          }

          .info-desc {
            font-size: 13px;
            color: #ffffff;
          }
        }

        .top-btnall {
          flex: 0 0 200px;
          display: flex;
          margin-top: 11px;
          margin-right: 16px;
          justify-content: space-between;

          div {
            flex: 0 0 49%;
            height: 38px;
            background: url("../../public/images/new/btnbg15.png") center no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            text-align: center;
            line-height: 38px;
            font-size: 14px;
            color: #000000;

            &:last-child {
              margin-left: 16px;
              background: url("../../public/images/new/btnbg14.png") center no-repeat;
              background-size: 100% 100%;
              color: #fff;
            }
          }
        }
      }

      .info-total {
        justify-content: space-between;

        .total {
          font-size: 12px;
          color: #a8a8a8;

          div {
            font-size: 14px;
            margin-bottom: 10px;
            color: #fff;
          }
        }
      }

      .top-btnall1 {
        display: none;
      }
    }
  }

  .h5manage-top {
    display: block;

    .top-name {
      margin: 8px 0 16px;
      font-size: 16px;
      color: #ffffff;
    }

    .top-title {
      font-size: 18px;
      color: #fff;
      font-weight: 600;
    }

    .top-list {
      margin: 0 0 12px;
      padding: 20px 16px;
      border-radius: 8px;
      background: #181818;
      flex-wrap: wrap;

      .info {
        flex: 0 0 33.3%;
        font-size: 12px;
        color: #a8a8a8;
        line-height: 12px;

        &:nth-child(-n + 7) {
          margin-bottom: 20px;
        }

        .color1 {
          color: #C9FA5B
        }

        &:last-child {
          flex: 1;
        }

        div {
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 10px;
          line-height: 14px;
        }

        p {
          display: flex;
          span{
                display: inline-table;
                width: 65px;
              }
          img {
            width: 12px;
            height: 12px;
            margin-left: 4px;
          }
        }
      }
    }

    .img1 {
      text-align: center;
      width: 140px;
      height: 140px;
      margin: 0 auto;
    }

    .top-total {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;

      .line-info {
        flex: 0 0 25%;
        font-size: 12px;
        color: #a8a8a8;

        div {
          margin-bottom: 12px;
          font-size: 16px;
          color: #46bb57;
        }

        &:nth-child(2) {
          div {
            color: #ffffff;
          }
        }

        &:nth-child(3) {
          div {
            color: #c9fa5b;
          }
        }

        &:nth-child(4) {
          div {
            color: #f8a430;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .no-data {
    padding: 50px 0;
  }
.modal-con{
  padding: 16px;
  width: calc(100vw - 8px);
  .modal-btncon{
    width: 100%;
  }
  .modal-title{
    margin-bottom: 16px;
  }
}

  .type-select {
    flex-wrap: wrap;

    .select {
      flex: 0 0 49%;
      margin-right: 2%;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:last-child {
        flex: 0 0 100%;
      }

      &:nth-child(-n + 4) {
        margin-bottom: 2%;
      }
    }
  }

  .manage {
    padding: 56px 0;

    .manage-con {
      .manage-name {
        font-size: 16px;
        line-height: 36px;

        .manage-btn {
          font-size: 14px;
        }
      }

    }
  }

  .h5manage-top {
    .top-total {
      .line-info {
        flex: 0 0 50%;

        &:nth-child(-n + 2) {
          margin-bottom: 22px;
        }
      }
    }
  }

  .h5manage-list {
    .h5list-info {
      .info-top {
        .top-btnall {
          display: none;
        }

        .info-title {
          flex-wrap: wrap;

          p {
            &:last-child {
              margin-top: 0;
              margin-left: 6px;
            }
          }
        }
      }

      .top-btnall1 {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        div {
          flex: 0 0 30%;
          height: 36px;
          background: url("../../public/images/new/btnbg35.png") center no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          line-height: 34px;
          font-size: 14px;
          color: #fff;

          &:first-child {

            background: url("../../public/images/new/btnbg36.png") center no-repeat;
            background-size: 100% 100%;
            color: #000;
          }
        }
      }

      .info-total {
        justify-content: flex-start;
        flex-wrap: wrap;

        .total {
          flex: 0 0 33.3%;

          &:nth-child(-n + 3) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
  